
import AES from '~/plugins/AES.js'
import _ from 'lodash'

export default {
  name: 'resetPasswordDialog',
  data() {
    return {
      disabledFlag: false,
      resetDialogVisible: false,
      email: '',
      butonContent: 'SEND RESET EMAIL',
      time: 60,
      timer: null,
      mailData: {
        Url: '',
        name: '',
        email: '',
      },
    }
  },
  watch: {
    email: {
      handler(newValue, oldValue) {
        if (newValue) {
          let reg =
            /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
          if (reg.test(this.email)) {
            this.disabledFlag = false
          } else {
            this.disabledFlag = true
          }
          this.getmailData()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getmailData()
  },
  methods: {
    goBack() {
      this.resetDialogVisible = false
      this.$emit('openLoginDialog')
    },
    getmailData() {
      this.mailData.Url = location.origin + '/setNewPassword'
      this.mailData.name = this.email
      this.mailData.email = AES.encrypt(this.email + '&t='+ Date.now())
    },
    debounceSendEmail:_.debounce(function () {
      this.sendEmail()
    }, 200),
    sendEmail() {
      if (this.email) {
        this.disabledFlag = true
        this.getmailData()
        this.timer = setInterval(() => {
          if (this.time == 0) {
            this.resetData()
          } else {
            this.butonContent = 'Resend Passwords in ' + this.time + ' s'
            this.time--
          }
        }, 1000)
        this.$axios
            .post('au/resetemail', {
              email: this.email,
              content: this.$refs.resetPasswordMail.$el.innerHTML,
            })
            .then(res => {
              if (res.code == 1) {
                this.resetData()
                this.time = 60
                this.$notify({
                  title: 'success',
                  message: 'Sending an email successfully',
                  type: 'success',
                  duration: 3000,
                })
              }
            })
            .catch(() => {
              this.resetData()
              this.time = 60
              // this.$message.error(error.response.data.msg);
            })
      }
    },
    resetData(boolean = false) {
      clearInterval(this.timer)
      this.disabledFlag = boolean
      this.butonContent = 'SEND RESET EMAIL'
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
